import React, { useState, useRef, useEffect } from "react";
import { Header } from "../../components";
// import { Statics } from "../../features/main/Statistics";
import { Partners } from "../../features/main/Partners";
import { Tokenomics } from "../../features/main/Tokenomics";
import { TokenInfo } from "../../features/main/TokenInfo";
import { FaqList } from "../../features/main/FaqList";
import { Footer } from "../../components/footer";
import { Advantages } from "../../features/main/Advantages";
// import { YoutubeVideo } from "../../features/main/YoutubeVideo";
import { Social } from "../../features/main/Social";
import { Background } from "../../features/main/Background";
import { GiveawayBanner } from "../../features/main/GiveawayBanner";
import { PancakeSwap } from "../../features/main/PancakeSwap";
import { isBefore } from "date-fns";
import { FIRST_THIRTY_DATE, SWAP_TOKEN_START } from "../../helpers/constants";
import { ThirtySwap } from "../../features/main/SwapToken/ThirtySwap";
import { useWeb3React } from "@web3-react/core";
import { useGetContract } from "../../helpers/hooks/useGetContract";
import { CONTRACT_NAMES } from "../../helpers/constants";
import { userExists } from "../../helpers/checks";
import { Timer } from "../../components";
import { MainSwap } from "../../features/main/SwapToken/MainSwap";
import config from "../../helpers/config";

export const Main = () => {
  const { account, chainId } = useWeb3React();
  const isAllowedChainId = Number(chainId) === Number(config.allowedChainId);
  const [isCompletedThirtyCountdown, setIsCompletedThirtyCountdown] = useState(
    !isBefore(new Date(), new Date(FIRST_THIRTY_DATE))
  );

  const [isCompletedCountdown, setIsCompletedCountdown] = useState(
    !isBefore(new Date(), new Date(SWAP_TOKEN_START))
  );

  const tm = useRef(null);
  const isFetching = useRef(false);
  const [contracts, setContracts] = useState({
    liquidityBNB: "",
    liquidityBUSD: "",
    frgx: "",
    multiplier: 0
  });

  const swapTokensRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const socialRef = useRef(null);

  const callFetch = async () => {
    let param = 0;

    if (!isCompletedThirtyCountdown && isCompletedCountdown) {
      param = Date.now();
    }

    fetch(
      `https://forsage-storage.s3.eu-central-1.amazonaws.com/frgx/config.json${
        param ? `?time=${param}` : ""
      }`
    )
      .then(result => {
        return result.json();
      })
      .then(res => {
        if (res.liquidityBNB) {
          tm.current && clearTimeout(tm.current);
          setContracts({
            liquidityBNB: res.liquidityBNB,
            liquidityBUSD: res.liquidityBUSD,
            frgx: res.frgx,
            multiplier: res.multiplier
          });
        } else {
          tm.current = setTimeout(callFetch, 1000);
        }
      })
      .catch(e => {
        tm.current && clearTimeout(tm.current);
        tm.current = setTimeout(callFetch, 1000);
      });
  };

  useEffect(() => {
    if (!isFetching.current) {
      isFetching.current = true;
      callFetch();
    }
  }, []);

  const [isRegistered, setIsRegistered] = useState(false);
  const { getContract } = useGetContract();

  const checkIsRegistered = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.BASE);
      const total = await userExists(account, contract);

      setIsRegistered(total);
    } catch (e) {
      setIsRegistered(false);
    }
  };

  useEffect(() => {
    if (isAllowedChainId) {
      checkIsRegistered();
    }
  }, [account, isAllowedChainId]);

  return (
    <div className="h-full bg-[#080909] relative flex flex-col items-center overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="flex flex-col items-center justify-center h-full bg-[#080909] w-full pt-7 sm:max-w-full sm:pt-6">
          <Header
            isRegistered={isRegistered}
            swapTokensRef={swapTokensRef}
            tokenomicsRef={tokenomicsRef}
            socialRef={socialRef}
          />
          <TokenInfo swapTokensRef={swapTokensRef} />
          {/* <Statics /> */}
          {isCompletedThirtyCountdown ? (
            <MainSwap
              isRegistered={isRegistered}
              contractsFetch={contracts}
              customRef={swapTokensRef}
            />
          ) : (
            <ThirtySwap
              isRegistered={isRegistered}
              contractsFetch={contracts}
              customRef={swapTokensRef}
            />
          )}
          <Partners />
          <Tokenomics customRef={tokenomicsRef} contractsFetch={contracts} />
          {/* <GiveawayBanner /> */}
          {/* <PancakeSwap /> */}
          <Advantages />
          <Background />
          {/* <YoutubeVideo /> */}
          <FaqList />
          <Social customRef={socialRef} />
          <img
            alt="icon net"
            className="absolute left-0  top-[1200px] z-[0] sm:hidden"
            src="/icons/net.svg"
          />
          <img
            className="absolute bottom-0 w-full h-[320px] left-1/2 -translate-x-1/2 z-[0] sm:h-[200px] sm:w-[800px] sm:max-w-fit"
            src="/images/mainPage/bottomShadow.png"
            alt="mainShadow"
          />
          <div className="absolute bottom-20 right-20 sm:right-5 flex text-white-100 pb-5 z-[10] sm:text-xs">
            <span>code: #FRGXupweGO</span>
          </div>
          {!isCompletedThirtyCountdown && (
            <div className="hidden">
              <Timer
                customDate={FIRST_THIRTY_DATE}
                isCompletedCountdown={isCompletedThirtyCountdown}
                setIsCompletedCountdown={setIsCompletedThirtyCountdown}
              />
            </div>
          )}
          {!isCompletedCountdown && (
            <div className="hidden">
              <Timer
                customDate={SWAP_TOKEN_START}
                isCompletedCountdown={isCompletedCountdown}
                setIsCompletedCountdown={setIsCompletedThirtyCountdown}
              />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};
