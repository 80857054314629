import React from "react";

export const Social = ({customRef}) => {
    const socialList = [
        {
            title: 'Twitter (X)',
            icon: 'twitter.svg',
            link: 'https://twitter.com/FRGXfinance',
        },
        // {
        //     title: 'YouTube',
        //     icon: 'youtube.svg',
        //     link: '',
        // },
        {
            title: 'Telegram',
            icon: 'telegram.svg',
            link: 'https://t.me/frgxfinance',
        },
        {
            title: 'Group Chat',
            icon: 'chat.svg',
            link: 'https://t.me/frgxgroup',
        },
    ]
    return (
        <div className="flex items-center pt-[159px] flex-col z-[1] pb-[160px] sm:pb-[70px] sm:pt-[80px]" ref={customRef}>
            <h2 className="text-[140px] title-text-color font-semibold sm:text-[40px] sm:pb-[25px]">Follow us</h2>
            <div className="grid grid-rows-1 grid-cols-3 gap-6 sm:grid-rows-2 sm:grid-cols-2 sm:gap-1">
                {socialList.map((item, itemIndex) => {
                    return (
                        <a href={item?.link} target="_blank" className="flex items-center justify-center w-[288px] h-[70px] sm:w-full sm:h-full sm:bg-transparent backdrop-blur-xl rounded-3xl bg-white-30 border-[1px] border-white-90 sm:border-none space-x-4 sm:p-6" key={itemIndex}>
                            <img src={`/icons/social/${item?.icon}`} alt={`social_${itemIndex}`} />
                            <span className="text-lg text-white inter font-light">{item?.title}</span>
                        </a>
                    )
                })}
            </div>
        </div>
    )
}