import React from "react";
import { useExternalScript } from "../../helpers/hooks/useExternalScript";

export const Giveaway = () => {
  const state = useExternalScript('https://sweepwidget.com/w/j/w_init.js');
  return (
    <div className="flex flex-col items-center w-full">
        {(state === 'loading' || state === 'ready') && <div id="75813-vo3z0exm" className="sw_container w-full"></div>}  
    </div>
  );
}