import React, {useMemo} from 'react';
import { ActivateModal } from '../Modals/ActivateModal';
import { useModal } from '../../helpers/hooks/useModal';
import { useWeb3React } from "@web3-react/core";
import { CommisionModal } from '../../components/Modals/CommisionModal';

export const Header = ({isRegistered, swapTokensRef, socialRef, tokenomicsRef }) => {
    const { openedModal, onOpen, onClose } = useModal();
    const { openedModal: openedCommisionModal, onOpen: onOpenCommisionModal, onClose: onCloseCommisionModal } = useModal();
    const {account, connector} = useWeb3React();


    const onConnect = () => {
        if (!account) {
            onOpen();
        }
    };

    const scrollToRef = (currentRef) => {
        window.scrollTo({ top: currentRef.current.offsetTop, behavior: 'smooth' });
      };

    const executeScroll = (currentRef) => {
        scrollToRef(currentRef);
      };

    const onDisconnect = () => {
        if (account) {
            void connector?.resetState();
        }
    }

    const statusColor = useMemo(() => {
        if (account) {
            if (isRegistered) {
                return {
                    iconUrl: '/icons/lowCommissionWallet.svg',
                    bg: 'border border-[#96F233] bg-green-30',
                    text: 'text-[#96F233]',
                }
            } else {
                return {
                    iconUrl: '/icons/highCommissionWallet.svg',
                    bg: 'border border-[#FFBB54] bg-orange-30',
                    text: 'text-[#FFBB54]',
                }
            }
        } else {
            return {
                bg: '',
                text: '',
            }
        }
    }, [account, isRegistered])

    return (
            <div className="flex justify-between w-full items-center fixed top-5 left-0 px-[108px] z-[999] sm:px-5 ">
                  <img className="sm:hidden" src="/images/header/frgxLogo.png"/>
                  <img className="hidden sm:flex" src="/images/header/frgxLogoMob.png"/>
                <div className="flex font-light rounded-2xl bg-black-200 border-[1px] border-white-100 backdrop-blur-md sm:hidden">
                  <button onClick={() => executeScroll(swapTokensRef)} className="text-white p-3">Swap tokens</button>
                  <button onClick={() => executeScroll(tokenomicsRef)} className="text-white p-3">Tokenomics</button>
                  <button onClick={() => executeScroll(socialRef)} className="text-white p-3">Social</button>
                </div>
                <div className="flex items-center justify-end space-x-5">
                    
                     
                        {account ? (
                            <div onClick={onOpenCommisionModal} className={`cursor-pointer relative ${statusColor?.bg} rounded-3xl flex items-center py-2.5 px-5 space-x-1.5`}>
                                <img src={statusColor?.iconUrl} className={`w-6 h-6`} />
                                <button className="flex items-center text-white justify-center rounded-3xl">
                                    <div className="w-full h-full relative flex flex-col items-center justify-center">
                                        <span className="text-sm font-semibold leading-none">{String(account).slice(0, 5) + '...' + String(account).slice(37)}</span>
                                        <span className='absolute bottom-[-10px] left-[12.5px] text-[6px]'>Click to show info</span>
                                    </div>
                                </button>
                            </div>
                        
                        ) : (
                            <button  className="flex items-center bg-white justify-center rounded-3xl py-3.5 px-6 space-x-1.5">
                                <span  onClick={onConnect} className="text-sm font-semibold leading-none">Connect Wallet</span>
                            </button>
                        )} 
                        
                    
                    {!!account && (
                        <div className="">
                            <img onClick={onDisconnect} alt="disconnect icon" className="cursor-pointer h-6 w-6" src="/icons/disconnect.svg"/>
                        </div>
                    )}
                 </div>
                <ActivateModal handleCloseModal={onClose} openedModal={openedModal} />
                <CommisionModal isRegistered={isRegistered} handleCloseModal={onCloseCommisionModal} openedModal={openedCommisionModal} />
            </div>
    )
}

