import { pancakeSwapAbi } from "./contractsAbi/swap";
import { matrixTokenAbi } from "./contractsAbi/token";
import { pancakeExchangeAbi } from "./contractsAbi/pancakeExchange";
import { frgxAbi } from "./contractsAbi/frgx";
import { liquidityAbi } from "./contractsAbi/liquidityAbi";
import { matrixXBaseAbi } from "./contractsAbi/xbase";
import { contractRouterAbi } from "./contractsAbi/router";

export default {
  // main_net
  pancakeSwapAddress: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
  pancakeExchange: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  contractBusd: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  contractBnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  contractXBASE: "0x5acc84a3e955bdd76467d3348077d003f00ffb97",
  contractRouter: "0x33C31572868f3Df6CD2647561D4c1f0BF1Bb9C9C",
  allowedChainId: "56",

  // test_net
  // pancakeSwapAddress: "0x0540cf59899d3fc2aFA0da83C5a7bA9617071D03",
  // pancakeExchange: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  // contractBusd: "0x000D5C98607ef98B7D6b4176BfC2D2635D1a72e3",
  // contractBnb: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  // contractFrgx: "0x5bC4cBE76fa2023d90F09dc9317FD531Fa120243",
  // contractXBASE: "0x172eA683094C6772159aBCe72b9F99D0D9a24f28",
  // contractRouter: "0xCfA40A7AE369Ba43B71348a66712F44E37A5B0A1",

  // busdLiquidityPool: "0x0102aac64f7241d2b004c0921574d712e1b5ba64",
  // bnbLiquidityPool: "0x286e6de327bd6c2b0d55291c3f9767a1685370cf",
  // allowedChainId: "97",

  matrixXBaseAbi: matrixXBaseAbi,
  pancakeSwapAbi: pancakeSwapAbi,
  matrixTokenAbi: matrixTokenAbi,
  contractPancakeExchangeAbi: pancakeExchangeAbi,
  liquidityPoolAbi: liquidityAbi,
  frgxAbi: frgxAbi,
  contractRouterAbi: contractRouterAbi,
};
