import React from "react";
import { Commission } from "./commission";
import { motion } from "framer-motion" 

export const Tokenomics = ({customRef, contractsFetch}) => {
    const proofsItem = [
        {
          title: 'Contract',
          href: '',
        },
        {
          title: 'Pancake pool',
          href: '',
        },
        {
          title: 'Security audit',
          href: 'https://forsage-storage.s3.eu-central-1.amazonaws.com/frgx/',
        },
        {
          title: 'F-ID (GitBooks)',
          href: 'https://docs.frgx.finance/',
        },
      ]

      const tokenInfo = [
        {
          title: 'Blockchain',
          desc: 'BNB Chain',
          position: 'right-[-150px] top-[-150px] lg:right-auto lg:left-[250px] lg:top-auto lg:bottom-[70px] ',
        },
        {
          title: 'Name',
          desc: 'FRGX Token',
          position: 'top-[-200px] left-[150px] lg:top-[50px] lg:left-[300px]',
        },
        {
          title: 'Fair Launch',
          desc: 'No presale',
          position: 'bottom-[-150px] left-[50px] lg:bottom-[170px] lg:left-[200px]',
        },
        {
          title: 'Max Supply',
          desc: '21,000,000',
          position: 'bottom-[-200px] right-[-50px] lg:bottom-[280px] lg:right-auto lg:left-[220px]',
        }
      ]


      

    return (
      <div className="w-full max-w-[1500px] px-[108px] sm:px-0 ">
        <div className="relative flex items-center mt-[203px] flex flex-col w-full min-h-[1600px] lg:min-h-full lg:h-full z-[1] overflow-hidden sm:h-full sm:px-5 sm:mt-[65px]" ref={customRef}>
          <div className="flex relative">
            <h2 className="text-[140px] title-text-color font-semibold pb-[150px] sm:text-[40px] sm:pb-0">Tokenomics</h2>
          </div>
          <div className="flex flex-col space-y-[100px] w-full sm:space-y-[50px]">
            <div className="relative flex items-center justify-cen pr-20 sm:pr-0 w-full min-h-[400px] lg:flex-col">
              <div className="relative flex w-[700px] lg:h-[500px]">
                  {tokenInfo?.map((item, itemIndex) => {
                            return (
                              <motion.div
                              initial={{x: 0, y: 0 }}
                              animate={{x: 15, y: 15 }} 
                              transition={{duration: 3, repeatType: "mirror", repeat: Infinity }}
                              className={`${item?.position} z-[20] absolute flex flex-col justify-between w-[288px] lg:w-[178px] rounded-3xl backdrop-blur-mini bg-white-30 shadow-wrapper py-[14px] px-6 border-[1px] border-white-80 z-[11]`} key={itemIndex}>
                                <span className="text-white-300 text-xl font-light inter leading-[36px] lg:text-[13px] lg:leading-[23px]">{item?.title}</span>
                                <span className="text-white font-medium text-[28px] lg:text-sm">{item?.desc}</span>
                              </motion.div>
                            );
                  })}
                <div className="flex items-center jsutify-center h-full w-full ">
                    <img className="absolute z-[1] left-[250px] lg:left-auto lg:right-[30px]" src="/images/tokenomics/frgxStar.png" alt='tokenomics_token'/>
                    <img className="absolute z-[0] left-[-50px] " src="/images/tokenomics/shadow1.png" alt='tokenomics_shadow'/>
                    <img className="absolute z-[0] left-[150px]" src="/images/tokenomics/shadow2.png" alt='tokenomics_shadow2'/>
                </div>   
              </div>
              {/* <div className="relative w-[288px] flex items-center justify-center sm:w-full z-[11]">
                <div className="absolute w-[85%] bg-white-30 h-full top-[15px] z-[0] rounded-3xl backdrop-blur-small border-[1px] border-white-60 opacity-80 sm:w-[85%]"> </div>
                <div className="w-full bg-white-30 rounded-3xl p-6 space-y-6 sm:w-full border-[1px] border-white-80 z-[1] backdrop-blur-xl">
                    <span className="text-white text-[28px] leading-[56px]">
                      Proofs
                    </span>
                    {proofsItem?.map((item, itemIndex) => {
                      return (
                        <div className="flex justify-between pb-2" key={itemIndex}>
                          <button className="text-xl text-[#828282] hover:text-white text-left whitespace-nowrap">
                            {item?.title}
                          </button>
                          <img className="rounded-full border-[1px] border-[#828282]" src="/icons/arrowRight.svg" alt={`arrowProofs_${itemIndex}`}/>
                        </div>
                      );
                    })}
                </div>
              </div> */}
            </div>
            <Commission contractsFetch={contractsFetch} />
            </div>
        </div>
    </div>
    )
}
