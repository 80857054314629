import React from "react";

export const Footer = () => {

    return (
         <div className="flex items-center h-[80px] bg-[#101111] z-[1]  bottom-0 w-full sm:p-5">
            <div className="flex flex-col space-y-2">
                <img alt="frgxGrayLogo" className="pl-[107px] sm:pl-0 sm:w-[105px] sm:h-[15px]" src="/icons/frgxGrayLogo.svg"/>
                <span className="absolute left-1/2 -translate-x-1/2  text-sm text-[#565656] sm:relative sm:text-[13px]">Copyright © FRGX.finance 2023</span>
            </div>
         </div>
    )
}