import React, { useState } from "react";

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

export const CommissionItem = ({ title, buy, sell, holders, liquidity, lost, marketing, referral, commisionInfo, hint }) => {

  const [isActive, setIsActive] = useState(false);

  return (   
        <AccordionItem className="relative flex flex-col space-y-6 h-full w-full z-[1] rounded-3xl bg-white-30  backdrop-blur-xl border-[1px] border-white-60 " >
                <AccordionItemHeading className="w-full">
                    <AccordionItemButton className="flex flex-col space-y-1 justify-between p-6 min-h-[130px]">
                        <button onClick={() => setIsActive(!isActive)}  className='flex items-start justify-between w-full space-x-2.5 '>
                            <div className="flex flex-col items-start space-y-1 w-full justify-between h-full">
                                <div className="flex items-center w-full justify-between">
                                    <span className="text-white text-xl sm:text-base">
                                        {title}
                                    </span>
                                    <div className="flex items-center justify-between space-x-2.5">
                                        {hint}
                                        <img className={`w-[24px] h-[24px] sm:w-[20px] sm:h-[20px] transition-all ease-out ${isActive ? 'rotate-180' :'rotate-0'}`}  src='/icons/FaqList/arrow.svg' alt='tokenomics_arrow'/>
                                    </div>
                                </div>
                                <div className="flex space-x-[48px]">
                                    <span className="text-[#46E064] font-medium text-xl sm:text-base">Buy - {buy}%</span>
                                    <span className="text-[#F34C92] font-medium text-xl sm:text-base">Sell - {sell}%</span>
                                </div>
                            </div>
                        </button>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="!mt-0 !pt-0 p-6 ">
                    <div className="flex flex-col items-center space-y-6">
                        <div className="flex flex-col h-full items-start w-full justify-between">
                            <span className="text-lg font-light text-[#828282] inter sm:text-[13px]"><span className="text-white">{liquidity}% </span> – liquidity pool </span>
                            <span className="text-lg font-light text-[#828282] inter sm:text-[13px]"><span className="text-white">{lost}%</span> – burned </span>
                            <span className="text-lg font-light text-[#828282] inter sm:text-[13px]"><span className="text-white">{marketing}%</span> – marketing </span>
                            {title === 'Basic Holder' ? <span className="text-lg font-light text-[#828282] inter sm:text-[13px]"><span className="text-white">{holders}%</span> – all FRGX holders with F-ID </span> : <span className="text-lg font-light text-[#828282] inter sm:text-[13px]"><span className="text-white">{referral}%</span> – referral rewards for uplines </span>}
                        </div>
                        {!!commisionInfo && commisionInfo}
                    </div>
                </AccordionItemPanel>
        </AccordionItem>
  )
}