import React, { useState } from "react";

// timer
import { SWAP_TOKEN_START } from "../../../helpers/constants";
import { isBefore } from 'date-fns';
import { Timer } from "../../../components";   
import { motion } from "framer-motion" 
    
export const TokenInfo = ({ swapTokensRef }) => {   
     // timer
    
    const [isCompletedCountdown, setIsCompletedCountdown] = useState(!isBefore(new Date(), new Date(SWAP_TOKEN_START)));
    
    const executeScroll = (currentRef) => {
        window.scrollTo({ top: currentRef.current.offsetTop, behavior: 'smooth' });
      };

     // timer end
    return (
        <div className="flex w-full max-w-[1500px] px-[108px] sm:px-0">
            <div className="flex items-center justify-center w-full ">
                <div className="relative w-full flex flex-col  pt-[180px] sm:pt-[309px] sm:px-5 min-h-[60vh] justify-center">
                    <h1 className="text-[80px] text-white font-medium leading-none z-[11] sm:text-3xl">FRGX Token</h1>
                    <span className="max-w-[472px] text-white opacity-50 font-light inter pt-10 pb-[60px] z-[11] sm:text-sm sm:pt-4 sm:pb-[48px]">Become a part of the most rapidly growing community of token holders on the BNB Chain in 2024</span>
                    <div className='token-buy-border h-[52px] w-[288px] sm:w-full rounded-3xl p-[1px] z-[11]'>
                        <button onClick={() => executeScroll(swapTokensRef)} className="bg-black w-full h-full rounded-3xl text-white text-2xl font-semibold ">
                            {isCompletedCountdown ? <span className="text-white text-lg font-medium sm:text-base">Buy $FRGX</span> : (
                                <Timer isCompletedCountdow={isCompletedCountdown} setIsCompletedCountdown={setIsCompletedCountdown}  />
                            )}
                        </button>
                    </div>
                    <img className="absolute right-[-20%] top-1/2 -translate-y-[45%] z-[0] w-[1400px] sm:top-[-125%] sm:right-auto sm:translate-y-0 sm:left-1/2 sm:-translate-x-1/2 sm:w-[1600px] sm:h-[1600px] sm:!max-w-none" src="/images/mainPage/shadow1.png" alt='tokenInfo_shadow'/>
                    <motion.img 
                        initial={{y: '-230%' }}
                        animate={{y: '-30%'}} 
                        transition={{duration: 1}}
                        className="absolute right-[16%] top-1/2 -translate-y-[30%] z-[1] sm:left-[30%] sm:-translate-x-1/2 sm:top-[130px] sm:w-[140px] sm:h-[140px]" src="/images/mainPage/frgxStar.png" alt='tokenInfo_token'> 
                    </motion.img>    
                </div>
            </div>
        </div>
    )
}