import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

export const Background = () => {

    const backgroundList = [
        {
            title: 'Benefits',
            desc:
            <>
                <span>Now, when many people are looking for the best cryptocurrencies for investment, it is important to be sure that the chosen token will live up to expectations and will be a reliable asset with a well-developed mechanism for value growth. It is also important that the token's smart contract is open and auditable so that holders can be confident in the security of their assets. You can find all this and even more in the FRGX token.</span>
                <span>Our goal is to make it to the top-10 BSC tokens with the most holders in 2024.</span>
                <span>Explore this page to learn more about the token and how to become part of the community.</span>
            </>
        },
        {
            title: 'Demand',
            desc:
            <>
                <span>Unlike many other tokens and ICO on the market, FRGX, even before its launch, has a huge audience of potential holders who are directly interested in buying it as soon as possible. These are the owners of F-ID, i.e. an account in one of the largest online business communities. They get additional benefits from owning the token and will ensure high stable demand.</span>
            </>
        },
        {
            title: 'History',
            desc:
            <>
                <span>The FRGX token has become a logical stage in the development of a global decentralized ecosystem that has been successfully operating for almost four years.</span>
                <span>A team of experts with many years of experience in launching blockchain projects for millions of participants spent more than a year developing the mechanics of launching self-sustainable cryptotokens with guaranteed stable price growth and long-term continued interest from a wide community of users.</span>
                <span>The result of their work was the FRGX token, which at the time of launch already has an audience of interested potential holders who are ready to buy it now and in the future. Tokenomics is designed in such a way that with each purchase both the price of the token and the demand for it in the cryptocurrency markets increase.</span>
            </>
        },
        {
            title: 'Features',
            desc:
            <>
                <span>An essential part of a sustainable economic model for the token is the elaborate way in which the funds received as fees on purchases and sales are allocated. For individual holders, this will be a small amount — as little as <span className="text-[#67FFDB]">5% or 6%</span>. However, due to large volumes, the funds collected will represent a significant amount. Some of it will go to the liquidity pool, some of it will be used to redeem and burn tokens to ensure the growth of their price, and some of it will be used to reward F-ID holders to ensure the inflow of new holders from this community. Also, part of the funds will be allocated for marketing purposes to ensure the promotion of the token on the market.</span>
            </>
        },
        {
            title: 'Security',
            desc:
            <>
                <span>The token launch will be accompanied and supported by professionals who have overseen the launch of many other promising blockchain startups. They will supervise the process and take the necessary steps to protect holders from any risks.</span>
                <span>One of the most important features of FRGX tokens is that 100% of tokens will be available on the free market from the first second. Not a single token will be initially withdrawn from circulation for the benefit of creators or influencers.</span>
            </>
        },
    ]

      const [width, setWidth] = useState(0);
      const carousel = useRef();

      useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth + 20);
      }, []);

    return ( 
        <div className="relative flex items-center mt-[203px] flex flex-col w-full h-full lg:min-h-full lg:h-full z-[1] overflow-hidden sm:h-full sm:mt-[65px]">
            <div className="flex relative">
                <h2 className="text-[140px] title-text-color font-semibold pb-[60px] sm:text-[40px] sm:pb-[20px]">Background</h2>
            </div>
            <motion.div ref={carousel} whileTap={{ cursor: "grabbing" }} className="relative cursor-pointer overflow-hidden w-full z-[1]">
                <motion.div  drag="x" dragConstraints={{right: 0, left: -width}} className="flex items-start justify-start space-x-10 sm:space-x-[15px]">
                {backgroundList?.map((item, itemIndex) => {
                    return (
                            <motion.div key={itemIndex} className='first:pl-5'>
                                <div className="flex flex-col space-y-6 h-[517px] w-[757px] sm:h-full sm:min-h-[620px] sm:w-[300px] bg-white-30 backdrop-blur-xl rounded-3xl border-[1px] border-[1px] border-white-100 p-6" key={itemIndex}>
                                    <span className="text-[32px] text-white poppins sm:text-xl">{item?.title}</span>
                                    <div className="flex space-y-[30px] flex-col text-[#828282] text-lg sm:text-sm inter h-full font-light leading-[32px] sm:space-y-4">
                                        {item.desc}
                                    </div>
                                </div>
                            </motion.div>
                        )
                })}
                </motion.div>
                
            </motion.div>
            <img className="absolute top-[240px] w-full h-[400px] z-[0] sm:top-[150px] sm:h-full" src="/images/background/bgLines.png"/>
            <img className="absolute top-[200px] z-[0] sm:top-[150px]" src="/images/background/shadow.png"/>
        </div>
    )
}