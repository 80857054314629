import React, { useEffect, useState } from "react";

import {CONTRACT_NAMES} from "../../../helpers/constants";
import {userExists} from "../../../helpers/checks";
import {useGetContract} from "../../../helpers/hooks/useGetContract";
import {useWeb3React} from "@web3-react/core";

import { Accordion } from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { CommissionItem } from './item';
import { Hint } from "../../../components";


export const Commission = ({ contractsFetch }) => {
  const {account} = useWeb3React();
  const { getContract } = useGetContract();

  const [isRegistered, setIsRegistered] = useState(false);

  const checkIsRegistered = async () => {
      try {
          const contract = await getContract(CONTRACT_NAMES.BASE);
          const total = await userExists(account, contract);

          setIsRegistered(total);
      } catch (e) {
          setIsRegistered(false);
      }
  };

  useEffect(() => {
      checkIsRegistered()
  }, [account]);

  const commisionList = [
    {
      title: 'Holder with F-ID',
      buy: '5',
      sell: '5',
      holders: '0',
      liquidity: '0,5',
      lost: '0,5',
      marketing: '1',
      referral: '3',
      commisionInfo: isRegistered && <span className="text-[#46E064] text-xl font-light ">Your commission is low!</span>,
      hint: <Hint type={1} contractsFetch={contractsFetch} />
    },
    {
      title: 'Basic Holder',
      buy: '6',
      sell: '6',
      holders: '3',
      liquidity: '1',
      lost: '1',
      marketing: '1',
      referral: '0',
      commisionInfo: !isRegistered && <span className="text-[#828282] text-xl font-light"><span className="text-[#F24A33]">Your commission is too high!</span> To have a reduced commission, please register</span>,
      hint: <Hint type={2} contractsFetch={contractsFetch} />
    },
  ]

    return (
      <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-12 sm:pt-6 ">
            <div className="relative w-full flex flex-col items-center">
              <div className="flex relative">
                <span className="text-[140px] title-text-color font-semibold pt-[60px] sm:pt-0 pb-[60px] sm:text-[40px]">Fees</span>
              </div>
              <div className="w-full flex items-center justify-center">
                  <Accordion allowZeroExpanded allowMultipleExpanded className="flex w-full space-x-6 sm:flex-col sm:space-x-0 sm:space-y-14 ">
                      {commisionList.map((item, itemIndex) => {
                        return (
                        <div key={itemIndex} className="flex items-center justify-center relative w-full h-full">
                          
                         <div className="absolute w-[90%] bg-white-30 h-[34px] top-[-15px] z-[0] rounded-3xl backdrop-blur-small border-[1px] border-white-90 opacity-80 sm:w-[85%]"></div>
                          <CommissionItem {...item} />
                        </div>
                        )
                      })}
                  </Accordion>
              </div>
            </div>
      </div> 
    )
}
