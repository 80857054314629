import React, { useState, useEffect, useMemo, Suspense } from "react";
import BigNumber from "bignumber.js";
import { useDebouncedEffect } from "../../../helpers/hooks/useDebounce";
import {
  CONTRACT_NAMES,
  MAX_VALUE,
  DEFAULT_GAS_LIMIT
} from "../../../helpers/constants";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import { useWeb3React } from "@web3-react/core";
import config from "../../../helpers/config";
import { increaseByPercent, toWei } from "../../../helpers/numbers";
import { callNotification } from "../../../helpers/notification";
import { addMinutes, getUnixTime } from "date-fns";
import { toast } from "react-toastify";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { useBalance } from "../../../helpers/hooks/useBalance";
import { Select } from "../../../components";
// timer
import {
  SWAP_TOKEN_START,
  FIRST_THIRTY_DATE
} from "../../../helpers/constants";
import { isBefore } from "date-fns";
import { motion } from "framer-motion";
import { Contract } from "@ethersproject/contracts";
import { liquidityAbi } from "../../../helpers/contractsAbi/liquidityAbi";

import { ConnectWallet } from "./comp/ConnectWallet";
import { CustomTimer } from "./comp/CustomTimer";

import { checkUserCommision } from "../../../helpers/checks";
import { transactionalNumberToNumber } from "./ThirtySwap";

export function realNumberToTransactionalNumber(
  amount,
  decimals = 18,
  fixed = undefined
) {
  return new BigNumber(amount, 10).multipliedBy(`1e${decimals}`).toFixed(fixed);
}

export const MainSwap = ({ customRef, contractsFetch, isRegistered }) => {
  const { account, provider, chainId } = useWeb3React();
  const { getContract } = useGetContract();
  const isAllowedChainId = Number(chainId) === Number(config.allowedChainId);
  // timer

  const [isCompletedCountdown, setIsCompletedCountdown] = useState(
    !isBefore(new Date(), new Date(SWAP_TOKEN_START))
  );

  // timer end

  // timer for first 30 min

  const [isCompletedThirtyCountdown, setIsCompletedThirtyCountdown] = useState(
    !isBefore(new Date(), new Date(FIRST_THIRTY_DATE))
  );

  const [payInputValue, setPayInputValue] = useState(10);
  const [amount, setAmount] = useState(0);

  const [isLoadingTransactionSwap, setIsLoadingTransactionSwap] = useState(
    false
  );
  const [isWaitingTransactionSwap, setIsWaitingTransactionSwap] = useState(
    false
  );
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isWaitingApprove, setIsWaitingApprove] = useState(false);
  const [isLoadingCheckApprove, setIsLoadingCheckApprove] = useState(false);
  const [isNeedApprove, setIsNeedApprove] = useState(false);
  const [chosenTokens, setChosenTokens] = useState(["BUSD", "FRGX"]);

  const {
    balanceBnb = 0,
    balanceBusd = 0,
    balanceFrgx = 0,
    fetchBalance
  } = useBalance({ contractsFetch, withoutFixed: true });

  const balanceMapper = {
    BUSD: balanceBusd,
    BNB: balanceBnb,
    FRGX: balanceFrgx
  };

  const onChangePayInputValue = e => {
    const value = Number(+e.target.value);
    if (!isNaN(value)) {
      setPayInputValue(e.target.value);
    }
  };

  const checkApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingCheckApprove) {
        setIsLoadingCheckApprove(true);

        const MIN_BALANCE = parseInt(MAX_VALUE, 16);
        try {
          const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
          const approveBalance = await contractBusd.allowance(
            account,
            config.pancakeExchange
          );

          const isAllowed = amount
            ? parseInt(approveBalance) >= parseInt(toWei(amount))
            : approveBalance >= MIN_BALANCE;

          if (isAllowed) {
            setIsNeedApprove(false);
          } else {
            setIsNeedApprove(true);
          }
        } catch (e) {
          setIsNeedApprove(true);
        }

        setIsLoadingCheckApprove(false);
      }
    }
  };

  useEffect(() => {
    if (amount && chosenTokens[0] === "BUSD" && isAllowedChainId) {
      checkApprove();
    }
  }, [amount, chosenTokens?.[0], isAllowedChainId, account]);

  const callApprove = async () => {
    if (isAllowedChainId) {
      if (!isLoadingApprove) {
        setIsLoadingApprove(true);

        try {
          let result;
          if (chosenTokens[0] === "BUSD") {
            const contractBusd = await getContract(CONTRACT_NAMES.BUSD);
            result = await contractBusd.approve(
              config.pancakeExchange,
              MAX_VALUE
            );
          }

          callNotification({
            type: "info",
            message: "Transaction was sent. Please wait",
            autoClose: 10000
          });

          setIsWaitingApprove(true);

          await result.wait();
        } catch (e) {
          setIsWaitingApprove(false);
          setIsLoadingApprove(false);
        }

        setIsWaitingApprove(false);
        setIsLoadingApprove(false);
        checkApprove();
      }
    }
  };

  const fetchSwapInfo = async () => {
    if (isAllowedChainId) {
      const isBusd = chosenTokens[0] === "BUSD";
      const contractAddr = isBusd
        ? contractsFetch.liquidityBUSD
        : contractsFetch.liquidityBNB;

      const contractPancake = await new Promise(function(resolve, rejected) {
        if (provider) {
          const contract = new Contract(
            contractAddr,
            liquidityAbi,
            provider?.getSigner(account).connectUnchecked() || provider
          );

          resolve(contract);
        } else {
          rejected("error init contract: ");
        }
      });

      const [reserve0, reserve1] = await contractPancake.getReserves();

      let price;

      if (isBusd) {
        price =
          transactionalNumberToNumber(reserve0.toString()) /
          transactionalNumberToNumber(reserve1.toString());
      } else {
        price =
          transactionalNumberToNumber(reserve0.toString()) /
          transactionalNumberToNumber(reserve1.toString());
      }

      console.log(price);

      setAmount(payInputValue * price);
    }
  };

  useEffect(() => {
    if (account && isAllowedChainId) {
      fetchBalance();
    }
  }, [account, chosenTokens, contractsFetch, isAllowedChainId]);

  useDebouncedEffect(
    async () => {
      if (
        account &&
        contractsFetch.liquidityBNB &&
        contractsFetch.liquidityBUSD
      ) {
        fetchSwapInfo();
      }
    },
    300,
    [payInputValue, account, chosenTokens[0], contractsFetch, isAllowedChainId]
  );

  const onSwapMain = async () => {
    if (isAllowedChainId) {
      const isBusdMode = chosenTokens[0] === "BUSD";
      if (!isLoadingTransactionSwap) {
        setIsLoadingTransactionSwap(true);

        try {
          const swapContractPancake = await getContract(
            CONTRACT_NAMES.PANCAKE_EXCHANGE
          );

          const contracts = isBusdMode
            ? [config.contractBusd, contractsFetch.frgx]
            : [config.contractBnb, contractsFetch.frgx];

          const totalPrice = parseFloat(payInputValue);

          const funcName = isBusdMode
            ? "swapExactTokensForTokens"
            : "swapExactETHForTokens";

          const params = isBusdMode
            ? [
                toWei(parseFloat(payInputValue)),
                toWei(1),
                contracts,
                account,
                getUnixTime(addMinutes(new Date(), 1))
              ]
            : [
                toWei(1),
                contracts,
                account,
                getUnixTime(addMinutes(new Date(), 1))
              ];

          const valueParams = {
            value: realNumberToTransactionalNumber(totalPrice)
          };

          const gas = await swapContractPancake.estimateGas[funcName](
            ...params,
            Object.assign({}, isBusdMode ? {} : valueParams)
          );

          const { wait } = await swapContractPancake[funcName](
            ...params,
            Object.assign(
              {
                gasLimit: gas ? gas.add(100000) : DEFAULT_GAS_LIMIT
              },
              isBusdMode ? {} : valueParams
            )
          );

          const swapBusdNotifLoading = callNotification({
            type: "info",
            message: `Swap to ${(
              amount * checkUserCommision(isRegistered)
            )?.toFixed(5)} FRGX in progress. Please wait`,
            autoClose: 30000
          });

          setIsWaitingTransactionSwap(true);
          const waitInfo = await wait();

          toast.dismiss(swapBusdNotifLoading);

          if (waitInfo.status === 0) {
            callNotification({
              type: "error",
              message: "Transaction failed. Try again"
            });
          } else {
            callNotification({
              type: "success",
              message: `Swap to ${(
                amount * checkUserCommision(isRegistered)
              )?.toFixed(5)} FRGX successful ✅`
            });
          }

          await fetchBalance();

          setTimeout(() => {
            setIsWaitingTransactionSwap(false);
            setIsLoadingTransactionSwap(false);

            fetchBalance();
          }, 1000);
        } catch (e) {
          setIsWaitingTransactionSwap(false);
          setIsLoadingTransactionSwap(false);
          callNotification({
            type: "error",
            message: parseErrorToUserReadableMessage(e)
          });
        }
      }
    }
  };

  const onChangeSelect = (value, index) => {
    if (index === 0) {
      return setChosenTokens([value, chosenTokens[1]]);
    } else {
      return setChosenTokens([chosenTokens[0], value]);
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  const renderSwap = useMemo(() => {
    const stylesProps = {
      backgroundImage: `url(/images/swap/timer/bg.svg)`,
      backgroundSize: "cover"
    };

    return (
      <>
        <div className="relative flex flex-col">
          <div className="flex justify-between px-5 py-2.5 bg-[#0C0C0C] rounded-2xl mb-[2.5px] ">
            <div className="flex flex-col justify-between space-y-2.5">
              <span className="text-sm text-white font-light">You pay</span>
              <input
                type="text"
                value={payInputValue}
                onChange={onChangePayInputValue}
                placeholder={payInputValue}
                className="focus:outline-none bg-[#0C0C0C] text-[#96F233] poppins text-[32px] h-[48px] w-[170px] font-medium  placeholder-[#96F233] "
              />
            </div>
            <div className="flex flex-col items-end justify-end space-y-2.5">
              <Select
                onChange={value => onChangeSelect(value, 0)}
                value={chosenTokens[0]}
                className="notranslate"
                data={[
                  {
                    icon: "/icons/tokens/BUSD.svg",
                    title: "BUSD"
                  },
                  {
                    icon: "/icons/tokens/BNB.svg",
                    title: "BNB"
                  }
                ]}
              />
              <span className="text-sm text-white font-light text-white-300">
                Balance: {balanceMapper[chosenTokens[0]]?.toFixed(4)}
              </span>
            </div>
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-center justify-center bg-[#0C0C0C] w-[45px] h-[45px] rounded-full">
            <div className="flex items-center justify-center bg-[#96F233] rounded-full w-[32px] h-[32px]">
              <img src="/icons/arrowBottom.svg" />
            </div>
          </div>
          <div className="flex justify-between px-5 py-2.5 bg-[#0C0C0C] rounded-2xl space-y-[5px] mt-[2.5px]">
            <div className="flex flex-col justify-between space-y-2.5">
              <span className="text-sm text-white font-light">You recive</span>
              <input
                type="text"
                disabled
                value={amount * checkUserCommision(isRegistered)}
                placeholder={amount * checkUserCommision(isRegistered)}
                className="focus:outline-none bg-[#0C0C0C] text-[#96F233] poppins text-[32px] h-[48px] w-[170px] font-medium  placeholder-[#96F233] "
              />
            </div>
            <div className="flex flex-col items-end justify-end space-y-2.5">
              <Select
                onChange={value => onChangeSelect(value, 1)}
                value={chosenTokens[1]}
                className="notranslate"
                data={[
                  {
                    icon: "/icons/tokens/FRGX.png",
                    title: "FRGX"
                  }
                ]}
              />
              <span className="text-sm text-white font-light text-white-300">
                Balance: {balanceMapper[chosenTokens[1]]?.toFixed(4)}
              </span>
            </div>
          </div>
        </div>

        {isOpen ? (
          <div className="flex flex-col items-center justify-center space-y-6">
            <button
              onClick={() => {
                if (isNeedApprove) {
                  callApprove();
                } else {
                  onSwapMain();
                }
              }}
              className="bg-[#96F233] w-full h-[66px] rounded-full sm:h-[48px]"
              disabled={
                isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove ||
                isLoadingCheckApprove
              }
            >
              <span className="text-xl text-black font-medium sm:text-lg">
                {isLoadingTransactionSwap ||
                isWaitingTransactionSwap ||
                isLoadingApprove ||
                isWaitingApprove ||
                isLoadingCheckApprove
                  ? "Loading..."
                  : isNeedApprove
                  ? "Approve"
                  : "Swap"}
              </span>
            </button>
            <button onClick={() => setIsOpen(!isOpen)}>
              <span className="text-sm text-white-700 font-light">
                For <span className="text-[#F24A33]">SELL FRGX </span>go
                to&nbsp;
                <span className="underline">pancakeswap</span>
              </span>
            </button>
          </div>
        ) : (
          <div className="relative flex flex-col items-center justify-between p-4 h-full bg-[#353535] space-y-[17px] backdrop-blur-small rounded-3xl border-[1px] border-white-100 ">
            <span className="text-white font-light">
              We set the maximum slippage to ensure the transaction is
              successful. if you want to control the slippage - go to
              pancakeswap
            </span>
            {!!contractsFetch.frgx && (
              <a
                target="_blank"
                href={`https://pancakeswap.finance/swap?inputCurrency=${contractsFetch.frgx}&outputCurrency=BNB`}
                className="flex items-center justify-center bg-lightGreen rounded-[50px] py-[9px] w-[221px] border-[1px] border-[#96F233]"
              >
                <span className="text-white font-medium ">
                  Go to Pancake.swap
                </span>
              </a>
            )}

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="absolute p-1 right-4 top-0 rounded-full bg-white-100"
            >
              <img className="w-3 h-3" src="/icons/closeIcon2.svg" />
            </button>
          </div>
        )}

        {!isCompletedCountdown && (
          <CustomTimer
            isCompletedCountdown={isCompletedCountdown}
            setIsCompletedCountdown={setIsCompletedCountdown}
          />
        )}
        {isCompletedCountdown && account && !isAllowedChainId && (
          <ConnectWallet changeNetwork={!isAllowedChainId} />
        )}
        {isCompletedCountdown && !account && <ConnectWallet />}
      </>
    );
  }, [
    isLoadingTransactionSwap,
    chosenTokens,
    balanceMapper,
    amount,
    payInputValue,
    isNeedApprove,
    isCompletedCountdown,
    isWaitingApprove,
    isLoadingApprove,
    isWaitingTransactionSwap,
    isCompletedThirtyCountdown,
    contractsFetch
  ]);

  return (
    <div className="w-full max-w-[1500px]">
      <div
        className="flex relative items-center mt-[159px] flex-col z-[1]"
        ref={customRef}
      >
        <h2 className="text-[140px] title-text-color font-semibold sm:text-[40px]">
          Swap tokens
        </h2>
        <div className="flex  justify-around w-full pt-[80px] sm:flex-col sm:relative sm:pt-[190px]">
          <div className="w-[500px] h-[500px] sm:max-w-[350px] sm:w-full sm:h-[95vw] sm:absolute sm:left-1/2 sm:-translate-x-1/2 sm:top-[3%] z-[0]">
            <motion.img
              animate={{ rotate: 360 }}
              transition={{ duration: 5, repeat: Infinity }}
              className="max-w-full w-full"
              src={"/images/swap/static_animation.png"}
            />
          </div>
          <div className="relative z-[1] flex flex-col justify-between w-[496px] sm:w-full sm:h-full h-full sm:min-h-[400px] rounded-3xl bg-white-30 px-6 pt-6 pb-[29px] sm:rounded-none space-y-[30px] border-[1px] border-white-90 backdrop-blur-small overflow-hidden">
            {renderSwap}
          </div>
          <img
            className="absolute z-[-1] h-[1200px] w-[1200px] !max-w-none top-1/2 -translate-y-[31%] right-[-20%] sm:left-1/2 sm:right-auto sm:-translate-x-1/2 sm:-translate-y-1/2"
            src="/images/swap/shadow.png"
            alt="swap_shadow"
          />
        </div>
      </div>
    </div>
  );
};
