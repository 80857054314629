import React from "react";

export const Partners = () => { 
    const partnersList = [
        {
            img: 'BNBchainIcon.svg',
            link: '',
        },
        {
            img: 'dexTools.svg',
            link: '',
        },
        {
            img: 'pancakeSwap.svg',
            link: '',
        },
        {
            img: 'coinMarketCap.svg',
            link: '',
        },
        {
            img: 'dappRadar.svg',
            link: '',
        },
        {
            img: 'mmPro.svg',
            link: '',
        },
    ]
    return (
        <div className="flex items-center pt-[203px] flex flex-col sm:px-5 sm:pt-[80px] z-[1]">
            <h2 className="text-[140px] title-text-color font-semibold sm:text-[40px]">Trusted by</h2>
            <div className="grid gap-6 grid-cols-3 grid-rows-2 mt-[80px] justify-center items-center sm:grid-cols-2 sm:grid-rows-3 sm:gap-8 sm:mt-[40px] sm:w-full">
            {partnersList?.map((item, itemIndex) => {
                return (
                <a key={itemIndex} href={item?.link} className="flex items-center justify-center rounded-[54px] w-[288px] h-[70px] hover:bg-white-30 cursor-pointer grayscale sm:grayscale-0 hover:grayscale-0 sm:hover:bg-transparent  sm:w-[158px] sm:h-[38px]">
                    <img alt="partnersImage" className='sm:w-full mx-auto' src={`/icons/partners/${item?.img}`}/>
                </a>
                );
            })}
            </div>
        </div>
    )
}