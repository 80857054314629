import React, { useState, useRef } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";

export const Advantages = () => { 

    const advantagesList = [
        [
            {
                title: "Fair Launch",
                desc: "Transparent conditions and equal opportunities without any whitelists, backdoors, or tokens held by owners. All tokens belong to the community. Every purchased token pumps the price.",
                positionRed: 'right-[-200px] bottom-[-200px]',
                positionGreen: 'top-[-250px] left-[-220px]' ,
            },
            {
                title: "Liquidity pool and token burning",
                desc: "A liquidity pool and allocating a part of the tokens to be burned will ensure stable growth of the token price without sharp drops.",
                positionRed: 'right-[-200px] top-[-200px]',
                positionGreen: 'bottom-[-150px] left-[-150px]' ,
            }
        ],
        [
            {
                title: "Security and Protection",
                desc: "Industry professionals provide extra security measures, such as security audit, contract verification, liquidity pool lock and protection, listing on DEX/CEX exchanges, and anti-bot solutions.",
                positionRed: 'left-[-150px] bottom-[-150px]',
                positionGreen: 'top-[-250px] right-[-220px]' ,
            },
            {
                title: "Support from a large Community on BNB chain",
                desc: "A network of 1.6 M+ enthusiastic users with F-ID gathered throughout almost 4 years of operation of the decentralized platform for online business.",
                positionRed: 'left-[-200px] top-[-200px]',
                positionGreen: 'bottom-[-150px] right-[-150px]' ,
            }
        ],
        [
            {
                title: "Self-Managed Token",
                desc: "Token emission is limited. Smart contract code is open, and the owner is unable to modify its terms. 100% of the tokens will be available on the free market at the moment of launch.",
                positionRed: 'right-[-200px] bottom-[-200px]',
                positionGreen: 'top-[-250px] left-[-220px]' ,
            },
            {
                title: "Partner bonus",
                desc: "Holders with F-ID receive additional rewards and are highly motivated to be engaged. F-ID users can get tokens automatically, and the number of holders will grow fast.",
                positionRed: 'right-[-200px] top-[-200px]',
                positionGreen: 'bottom-[-150px] left-[-150px]' ,
            }
        ],
    ]

    const customPadding = [
        "pt-0",
        "pt-[50px] sm:pt-0",
        "pt-[100px] sm:pt-0",
    ]

    const ref = useRef(null);
    const { scrollY } = useScroll({target:ref, offset: ["start start", "end start"]})
    const y = useTransform(scrollY, [0, 1], ["0%", "0%"]);


    const renderCol = (col) => {
        return col?.map((item, itemIndex) => { 
            return (
                <motion.div style={{ y }} className="h-[350px] relative bg-white-30 backdrop-blur-xl rounded-3xl p-6 border-[1px] border-white-70 flex flex-col space-y-6 overflow-hidden sm:h-full sm:space-y-2" key={itemIndex}>
                    <h3 className="text-[32px] text-white font-medium sm:text-base">{item?.title}</h3>
                    <span className="text-lg inter text-[#828282] font-light sm:text-sm sm:leading-[25px]">{item?.desc}</span>
                    <img className={`absolute ${item?.positionRed}`} src="/images/advantages/redShadow.png" alt={`redShadow_${itemIndex}_${col}`}/>
                    <img className={`absolute ${item?.positionGreen}`} src="/images/advantages/greenShadow.png" alt={`greenShadow_${itemIndex}_${col}`}/>
                </motion.div>
            );
            })
    }
    return (
        <div className="w-full max-w-[1500px] px-[108px] sm:px-0">
            <div className="relative flex items-center flex flex-col relative w-full z-[1] overflow-hidden sm:h-full sm:px-5 z-[1] sm:pt-[60px]">
                <h2 className="text-[140px] title-text-color font-semibold pb-[50px] sm:text-[40px] sm:pb-[40px]">Advantages</h2>
                <div className="flex justify-between space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6">
                    {advantagesList?.map((col, index) => {
                        return  <div className={`flex flex-col space-y-6 ${customPadding[index]}`} key={index}>{renderCol(col)}</div>
                    })}
                </div>
            </div>
        </div>
    )
}


